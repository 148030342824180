import React, { FunctionComponent } from "react";
import { Icon, Tooltip } from "@ledr/instruments";
import "./Toolbox.scss";
import './Hud.scss'

interface I_Tool {
  icon: string;
  state: boolean;
  onClick: () => void;
  label?: string;
}

interface I_ToolboxProps {
  tools: I_Tool[];
}

const Toolbox: FunctionComponent<I_ToolboxProps> = (props) => {
  return (
    <div >
				<div className={"toolbox"}>
					{props.tools?.map?.((tool,i) => (
				<div key={i}>
          <Tooltip text={tool.label}>
				{/*
				// @ts-ignore */}
            <button className={tool.state ? "active": ""} onClick={tool.onClick}>
              <Icon name={tool.icon} size={30} />
            </button>
          </Tooltip>
				</div>
      ))}
				</div>
    </div>
  );
};

export default Toolbox;
