//@ts-ignore
import { notif } from '@ledr/layout'
import React, {
	FunctionComponent,
	useState,
	useEffect,
	useContext,
	useMemo,
} from 'react'

import Avial, { AvialType } from '@ledr/ts-client'
import { EntityInput, WindowChild, Tooltip } from '@ledr/instruments'
import { Icon, wmContext } from '@ledr/instruments'

import { useDispatch, useSelector } from 'react-redux'
import { addLogAction } from '../../store/log/actions'

import ApiContext from '../../context/api'
import InstrumentsLinkerContext from '../../context/instrumentsLinker'
import { AppState } from '../../store/types'

import Three from '../../components/three/Three'

import { Panel, PanelContent } from '../../components/hud/Panel'
import Searchbar from './Searchbar'
import { iconClass } from '../../style/icons'
import Hud from '../../components/hud/Hud'
/*
import jsonTest from "./test.json";
import jsonTest2 from "./test2.json";
 */
import jsonTest3 from './test3.json'
import Authorizations from '../Settings/Authorizations'

function BFS(root) {
	let queue = [root]
	let explored = []

	while (queue.length) {
		let current = queue.shift()
		explored.push(current.pid)

		Object.entries(current.children).forEach(([key, value]) => {
			if (!explored.find((e) => e === key)) queue.push(value)

			//@ts-ignore
			if (!value.BfsParent && key !== current.pid)
				//@ts-ignore
				value.BfsParent = current.pid
		})
	}
}

interface ViewportProps {}
const Viewport: FunctionComponent<ViewportProps> = (props) => {
	const WmContext = useContext(wmContext)
	const dispatch = useDispatch()
	const api = useContext(ApiContext)
	const token = useSelector(
		(state: AppState) =>
			state.user.keychain.accesses[state.user.keychain.current].token
	)

	const [rootEntity, setRootEntity] = useState(
		new Avial.Values.V_Entity('<1>')
	)
	const [depth, setDepth] = useState(1)
	const [selectedEntity, setSelectedEntity] = useState(rootEntity)
	const [selectedEntities, setSelectedEntities] =
		useState<AvialType.Values.V_Array>(new Avial.Values.V_Array([]))
	const [hidded, setHidded] = useState([])
	const [currentEntity, setCurrentEntity] = useState(rootEntity)

	const [path, setPath] = useState([])
	const [links, setLinks] = useState([])
	const [nodes, setNodes] = useState([])

	const [filters, setFilters] = useState({
		classes: [],
		category: [],
	})

	function logger(args) {
		dispatch(
			addLogAction({
				timestamp: Date.now(),
				type: args.res.error ? 'error' : 'debug',
				channel: 'Viewport',
				msg: args,
				statusCode: args.res.error ? args.res.error : null,
			})
		)
	}

	const restartBFS = useMemo(() => {
		return () => {
			const frame = new Avial.AtapiMessage()

			frame.fromObj({
				COMMAND: 'INVOKE',
				METHOD: 'EXECUTE',
				PRECEDENCE: 1,
				ENTITY: new Avial.Values.V_Entity('<888>').value,
				NAME: 'SEARCH',
				AUTHORIZATION: new Avial.Values.V_Authorization(token).value,
				VALUE: {
					tag: 21,
					data: new TextEncoder().encode(
						new Avial.Values.V_Aggregate({
							root: new Avial.Values.V_Entity(
								rootEntity.toString()
							),
							authorization: new Avial.Values.V_Authorization(
								token
							),
							depth: new Avial.Values.V_Integer(depth),
							//max_nodes:new Avial.Values.V_Integer(0),
						}).toString()
					),
				},
			})

			api.session.pool
				//@ts-ignore
				.post(frame.toHgtp().value, logger)
				.then((e) => {
					//@ts-ignore
					console.log('RESULT => ', e)

					let r = new Avial.Values.V_Aggregate(
						Avial.AvValue_From_AtapiValue(
							//@ts-ignore
							Avial.HGTP_Unpack_Value(e.value)
						).value
					)
					console.log('====>', r)

					let newNodes = r.value['nodes'].map((e, i) => {
						return {
							entity: e.value?.['entity']?.toString(),
							id: e.value?.['entity']?.toString(),
							pid: e.value?.['entity']?.toString(),
							name: e.value?.['name']?.value,
							class: e.value?.['class']?.value,
							category: e.value?.['category']?.value,
						}
					}).value

					console.log(newNodes)
					//@ts-ignore
					setNodes(newNodes)

					let links = []
					r.value['links'].value.forEach((e, i) => {
						console.log(e, i)
						let founded = links.find(
							(ll) => ll.src === e.src && ll.dst === e.value.dst
						)

						if (!founded)
							links.push({
								src: e.value.src.toString(),
								dst: e.value.dst.toString(),
								locutor: [e.value.loc],
							})
						else founded.locutor.push(e.value.loc)
					})

					setLinks(links)

					// COMPUTE NUMBER OF CLASSES / CAT FOR HUD
					let classes = []
					let category = []

					newNodes.forEach((n) => {
						let classFound = classes.find(
							(c) => c.class === n.class
						)
						if (!classFound)
							classes.push({
								class: n.class,
								count: 1,
								edge: false,
								display: true,
							})
						else classFound.count += 1

						let categoryFound = category.find(
							(c) => c.category === n.category
						)
						if (!categoryFound)
							category.push({
								category: n.category,
								count: 1,
								display: true,
							})
						else categoryFound.count += 1
					})

					classes.sort((a, b) => b.count - a.count)
					category.sort((a, b) => b.count - a.count)
					setFilters({ classes, category })
				})
				.catch((err) => console.log('decodeFail', err))
		}
	}, [token, depth, rootEntity])

	useEffect(() => restartBFS(), [depth, rootEntity])

	const [graph, setGraph] = useState({})
	useEffect(() => {
		let newGraph = {}

		links.forEach((l) => {
			if (!newGraph[l.src])
				newGraph[l.src] = {
					name: nodes?.find?.((n) => n.pid === l.src)?.name,
					class: nodes?.find?.((n) => n.pid === l.src)?.class,
					pid: l.src,
					children: {},
					parent: {},
				}
			if (!newGraph[l.dst])
				newGraph[l.dst] = {
					name: nodes?.find?.((n) => n.pid === l.dst)?.name,
					class: nodes?.find?.((n) => n.pid === l.dst)?.class,
					pid: l.dst,
					children: {},
					parent: {},
				}
			newGraph[l.src].children[l.dst] = newGraph[l.dst]
			newGraph[l.dst].parent[l.src] = newGraph[l.src]
		})
		setGraph(newGraph)
	}, [links])

	useEffect(() => {
		let root = rootEntity.toString()
		let current = selectedEntity.toString()

		Object.entries(graph).forEach(([key, value]) => {
			delete value['BfsParent']
		})

		if (graph[root]) {
			BFS(graph[root])
		}

		let path = [graph[current]]
		while (current !== root && current) {
			current = graph[current]?.BfsParent
			path.unshift(graph[current])
		}
		setPath(path)
	}, [graph, selectedEntity, rootEntity])

	///////////////////////////////////////

	const MyContext = useContext(InstrumentsLinkerContext)

	useEffect(() => {
		MyContext?.out?.['selectedEntity']?.(selectedEntity)
	}, [MyContext?.out?.['selectedEntity'], selectedEntity])

	useEffect(() => {
		MyContext?.out?.['currentEntity']?.(currentEntity)
	}, [MyContext?.out?.['currentEntity'], currentEntity])

	useEffect(() => {
		MyContext?.out?.['selectedEntities']?.(selectedEntities)
	}, [MyContext?.out?.['selectedEntities'], selectedEntities])

	useEffect(() => {
		let explored = []

		function dig(node) {
			if (explored.findIndex((e) => e === node.pid) !== -1)
				return {
					name: node.name,
					class: node.class,
					pid: node.pid,
					circular: true,
				}

			explored.push(node.pid)

			return {
				name: node.name,
				pid: node.pid,
				class: node.class,
				children: Object.values(node.children).map((n) => dig(n)),
			}
		}

		if (graph[rootEntity.toString()]) {
			let smooth = dig(graph[rootEntity.toString()])
			MyContext?.out?.['BFS']?.(smooth)
		}
	}, [MyContext?.out?.['BFS'], graph])

	useEffect(() => {
		MyContext?.refreshDeclareIn?.('reload', restartBFS)
	}, [restartBFS])

	useEffect(() => {
		MyContext.declareInOut({
			in: [
				{ port: 'rootEntity', setter: setRootEntity },
				{ port: 'currentEntity', setter: setCurrentEntity },
				{ port: 'reload', setter: restartBFS, type: 'cb' },
			],
			out: [
				{ port: 'currentEntity' },
				{ port: 'selectedEntity' },
				{ port: 'selectedEntities' },
				{ port: 'BFS' },
				{ port: 'graph' },
			],
		})
		return () => {
			MyContext.unDeclareInOut()
		}
	}, [])

	///////////////////////////////////////

	let NODES = useMemo(() => {
		return nodes
			.filter(
				(n) =>
					filters.classes.find(
						(fc) => fc.class === n.class && fc.display === true
					) &&
					//@ts-ignore
					!hidded.find((h) => h === n.entity)
			)
			.map((n) => {
				return {
					...n,
					id: n.entity,
					entity: n.entity,
				}
			})
	}, [nodes, filters, hidded, rootEntity])

	const tab = useMemo(
		() => (
			<>
				<div
					style={{
						width: '33%',
						display: 'flex',
						lineHeight: '20px',
					}}
				></div>

				<div
					style={{
						width: '33%',
						display: 'flex',
						lineHeight: '20px',
					}}
				>
					<EntityInput
						value={rootEntity}
						onChange={(entity) => setRootEntity(entity)}
					/>
				</div>

				<div
					style={{
						width: '33%',
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<div></div>
					<div
						style={{
							display: 'flex',
							lineHeight: '20px',
						}}
					>
						Depth
						<input
							style={{ width: '100px' }}
							type={'range'}
							value={depth}
							min={1}
							max={15}
							onChange={(e) => setDepth(Number(e.target.value))}
						/>
						[{depth}]
					</div>
				</div>
			</>
		),
		[rootEntity, hidded, setHidded, selectedEntities, WmContext, nodes]
	)

	useEffect(() => {
		MyContext?.out?.['graph']?.({ NODES, links })
	}, [MyContext?.out?.['graph'], NODES, links])

	const win = useMemo(
		() => (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
				}}
			>
				<Hud>
					<Three
						nodes={NODES}
						links={links}
						offset={0}
						selectedEntity={selectedEntity}
						currentEntity={currentEntity}
						rootEntity={rootEntity}
						onSelect={setSelectedEntity}
						onSelected={setSelectedEntities}
						classes={filters.classes}
						setClasses={(index, content) => {
							setFilters({
								...filters,
								classes: filters.classes.map((nc, j) =>
									index === j
										? {
												...nc,
												...content,
										  }
										: nc
								),
							})
						}}
						contextMenu={[
							{
								name: 'Set as Root',
								onClick: (entity) => {
									setRootEntity(
										new Avial.Values.V_Entity(entity)
									)
								},
							},
							{
								name: 'View Entity',
								onClick: (entity) => {
									WmContext.wmEvents.newFloatWindow({
										component: 'EntityViewer',
										id: Math.random().toString(),
										// ratio number
										// posX  number
										// posY  number
										width: 1000,
										height: 1000,
										//
										data: { entity: entity },
									})
								},
							},
							{
								name: 'Edit Entity',
								onClick: (entity) => {
									WmContext.wmEvents.newFloatWindow({
										component: 'createEntity',
										id: Math.random().toString(),
										// ratio number
										// posX  number
										// posY  number
										width: 1000,
										height: 1000,
										//
										data: { entity: entity },
									})
								},
							},

							{
								name: 'Copy <ID>',
								onClick: (entity) => {
									navigator.clipboard.writeText(
										entity.toString()
									)
									notif.info(
										`Entity ${entity} content <br/> copied in clipboard`
									)
								},
							},

							{
								name: 'Hide',
								onClick: (entity) => {
									setHidded([...hidded, entity.toString()])
								},
							},
						]}
					/>
				</Hud>

				<Panel position={'bottomRight'}>
					<PanelContent title={`Hidden[${hidded.length}]`}>
						<button
							onClick={() => {
								setHidded([
									...hidded,
									...selectedEntities.value.map((e) =>
										e.toString()
									),
								])
							}}
						>
							Hide selected
						</button>
						<button
							onClick={() => {
								setHidded([])
							}}
						>
							Unhide all [{hidded.length}]
						</button>
						<div
							style={{
								padding: '5px',
								display: 'grid',
								gridTemplateColumns: 'repeat(3,auto)',
								maxHeight: '200px',
								overflow: 'auto',
							}}
						>
							{hidded.map((e) => (
								<>
									{e}
									<br />
								</>
							))}
						</div>
					</PanelContent>

					<PanelContent
						title={`Selection [${selectedEntities.value.length}]`}
					>
						<div
							style={{
								padding: '5px',
								display: 'grid',
								gridTemplateColumns: 'repeat(3,auto)',
								maxHeight: '200px',
								overflow: 'auto',
							}}
						>
							{selectedEntities.value.map((e) => (
								<>
									{e.toString()}
									<br />
								</>
							))}
						</div>
					</PanelContent>
					<PanelContent title={`Path`} isOpen={true}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								fontSize: '12px',
							}}
						>
							{!path[0] && <>No path from # TO # </>}

							{path[0] &&
								path.map((e) => (
									<div
										style={{
											borderRight: '1px solid grey',
											padding: '4px',
										}}
										onClick={() =>
											setSelectedEntity(
												new Avial.Values.V_Entity(
													e?.pid
												)
											)
										}
									>
										<Tooltip text={e?.pid} position={'top'}>
											{e?.name}
										</Tooltip>
									</div>
								))}
						</div>
					</PanelContent>
				</Panel>

				<div
					style={{
						position: 'absolute',
						left: '50%',
						marginLeft: '-150px',
						width: '300px',
						top: '40px',
					}}
				>
					<Searchbar
						value={''}
						list={nodes}
						onChange={setSelectedEntity}
						iconList={iconClass}
					/>
				</div>
			</div>
		),
		[
			rootEntity,
			selectedEntity,
			selectedEntities,

			currentEntity,

			NODES,

			hidded,
			nodes,
			links,
			filters,
			path,
			rootEntity,
			depth,
			setDepth,
		]
	)
	return <WindowChild tab={tab}>{win}</WindowChild>
}

export default Viewport
