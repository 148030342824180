
import Avial, {AvialType} from "@ledr/ts-client";

function flowToProgramm(flow) {

	let queue = flow.nodes?.filter(n =>
		flow.edges.find(e => e.target === n.id) === undefined
	)

	let explored = queue?.length > 0
		? [...queue]
		: [];
	let sorted = [];
	let loop = 0;

	while(queue && queue.length > 0 && loop <= 10) {
		let current = queue.shift();
		sorted.push(current);
		let edges = flow.edges?.filter(e => e.source === current.id);
		// collaps instruction by target
		if (edges.length > 0)
			edges = [edges[0]];

		let nodes = edges.map( e => flow.nodes.find(n => n?.id === e.target))

		nodes = nodes.filter((e, i) => {
			return nodes.find((n,j) => n?.id === e?.id  && i === j && !explored.find( e => e === n?.id))
		})
		queue.push( ...nodes )
		explored.push( ...nodes.map(n => n?.id) )
		loop++
	}

	console.log(queue, sorted)

	let	res = sorted
		.filter(s=> s.type === "adapter")
		// only keep BASE
		.filter(s=> s.data?.interface?.base !== undefined)
		// inject <adress>
		.map((s)=>
			{
				let request = new Avial.Values.V_Locutor(s.data.interface.base);
				request.setEntity(s.data.entity)

				return {
					id      : s.id,

					outputs : flow.edges.filter(e => e.source === s.id),
					inputs  : flow.edges.filter(e => e.target === s.id),

					value   : s.data.value ?? s.data.interface.in,
					hgtp: s.data.hgtp ?? {},

					request
				}
			}
		)

	console.log(res)
	return res;
}

async function executeLocutor(instruction, api){

let value = instruction.value
	? {
			type: instruction.value.type ,
			value: instruction.value.value
		}
		:
		{
			type: "NULL",
			value: ""
		}

	if (value.type === "INTERCHANGE" || value.type === "AGGREGATE")
		value.value = JSON.stringify(value.value)

	let args = {
		entity        :
		instruction.hgtp?.entity?.value ??  new Avial.Values.V_Entity(instruction.request.value.ENTITY).value,
		aspect        : instruction.request.value.ASPECT,
		method        : instruction.request.value.METHOD,
		name          : instruction.request.value.NAME,
		key           : instruction.request.value.KEY,
		attribute     : instruction.request.value.ATTRIBUTE,
		authorization : api.keychain,

//	value         : Avial.Utils.Value.fromTagAndString(Avial.Taxonomy.Tag.INTERCHANGE, Avial.Common.Any_ToString(obj)),
//	precedence    : Avial.Taxonomy.Precedence.GRAPH,
//	timeout       : 1000n,
		value         : value
	}

	if (instruction.hgtp?.aspect)
		args.aspect = instruction.hgtp?.aspect;
	if (instruction.hgtp?.name)
		args.name= instruction.hgtp?.name;
	if (instruction.hgtp?.key)
		args.key= instruction.hgtp?.key;
	if (instruction.hgtp?.attribute)
		args.attribute = instruction.hgtp?.attribute + "_ATTRIBUTE";

	console.log("WILL INVOKE args:", args, instruction)

	const invokeResult = await api.session.invokeEntity(args);
	console.log("HAS INVOKE result: ", invokeResult)

	return invokeResult;
}

/////////////////////////////////////////////////////////////////////////////////

async function executeProgramm(flow, api){
	let programm = flowToProgramm(flow)

  for(const instruction of programm) {
		console.log("-Will exec ------------------------------")
		console.log({ instruction})

		let res = await executeLocutor(instruction, api)

		instruction.outputs.forEach(o => {

			let instructTarget = programm.find(i => i.id === o.target)
			let valueToInject;

			if (res.value.type === "AGGREGATE")
			{
				let parsed = new Avial.Values.V_Aggregate(res.value.value);
				valueToInject = parsed.value[o.sourceHandle] // "result"
			}

			if (res.value.type === "INTERCHANGE")
			{
				valueToInject = new Avial.Values.V_Interchange(res.value.value);
			}

			if (res.value.type === "INTEGER")
			{
				valueToInject = new Avial.Values.V_Integer(res.value.value);
			}

			if (res.value.type === "ENTITY")
			{
				valueToInject = new Avial.Values.V_Entity(res.value.value);
				console.log("BUILD VALUE", res, valueToInject);
			}

			if (res.value.type === "STRING")
			{
				valueToInject = new Avial.Values.V_String(res.value.value);
			}

			if (res.value.type === "FLOAT")
			{
				valueToInject = new Avial.Values.V_Float(res.value.value);
			}


			let target = o.targetHandle;
			if (target.match(/^VALUE_.*/g))
			{
				target = target.slice(6)
				instructTarget.value.value[target] = valueToInject;
			}
			else if (target.match(/^ARGS_.*/g))
			{
				console.log("ARGS", target, valueToInject, res.value.type, instructTarget)
				target = target.slice(5)
				instructTarget.hgtp[target.toLowerCase()] = valueToInject;
			}
			else // target => VALUE
			{
				instructTarget.value = valueToInject;
			}



			console.log({ instructTarget })
		})

  }
	
	console.log("PROGRAMME TO EXECUTE", programm)
}

/////////////////////////////////////////////////////////////////////////////////

export {flowToProgramm};
export default executeProgramm;
