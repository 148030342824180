import * as THREE from 'three'
import ForceNode from './forceNode'

function edges(nodes: ForceNode[], opts) {
	let nodesLen = nodes.length
	for (let i = 0; i < nodesLen; i++) {
		let nodeA = nodes[i]
		if (nodeA.linkChildren.length < 2) continue

		// CENTER HYPEREDGE
		var centre = new THREE.Vector3()
		nodeA.linkChildren.forEach((e) =>
			centre.add(e.dst.group.position.clone())
		)
		centre.divideScalar(nodeA.linkChildren.length)
		nodeA.nextPos.lerp(centre, 1)
	}
}

function standard(nodes: ForceNode[], opts) {
	let nodesLen = nodes.length

	for (let i = 0; i < nodesLen; i++) {
		let node = nodes[i]
		node.virtualPos = node.group.position.clone()
		//node.nextPos = node.group.position.clone()
	}

	for (let pass = 0; pass < opts.passes; pass++) {
		for (let i = 0; i < nodesLen; i++) {
			let node = nodes[i]
			node.virtualPos = node.nextPos.clone()
			node.nextPos = node.virtualPos.clone()
		}

		// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

		for (let i = 0; i < nodesLen; i++) {
			let nodeA = nodes[i]
			for (let j = 0; j < nodesLen; j++) {
				let nodeB = nodes[j]
				//if (nodeB.isEdge) return
				let distance = nodeA.virtualPos.distanceTo(nodeB.virtualPos)
				if (distance > 1) distance = 1

				// REPULSION
				nodeA.nextPos.add(
					new THREE.Vector3()
						.subVectors(nodeA.virtualPos, nodeB.virtualPos)
						.normalize()
						.multiplyScalar(
							(1 - distance) * opts.repulsion * nodeB.repulsion
						)
				)

				// GRAVITY
				nodeA.nextPos.add(
					new THREE.Vector3()
						.subVectors(nodeB.virtualPos, nodeA.virtualPos)
						.normalize()
						.multiplyScalar(distance * opts.gravity)
				)
			}

			nodeA.linkChildren.forEach((link) => {
				if (link.src.isEdge) return
				if (link.src.hidden || link.dst.hidden) return

				let VposA = link.src.virtualPos.clone()
				let VposB = link.dst.virtualPos.clone()
				/*
				VposA.setZ(0)
				VposB.setZ(0)
				*/

				let distance = VposB.distanceTo(VposA)
				// LINK STRENGTH
				let vec = new THREE.Vector3()
					.subVectors(VposA, VposB)
					.normalize()
					.multiplyScalar(distance * opts.linkStrength)

				link.src.nextPos.sub(vec)
				link.dst.nextPos.add(vec)
			})
		}

		// // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

		for (let i = 0; i < nodesLen; i++) {
			let node = nodes[i]

			let P = node.virtualPos.clone().lerp(node.nextPos, 0.01)

			var deltaVector = new THREE.Vector3()
			deltaVector.subVectors(P, node.virtualPos)

			let magnitude = deltaVector.length()

			if (magnitude > 0.1) {
				deltaVector.normalize().multiplyScalar(0.1)
			}

			node.nextPos = deltaVector.add(node.virtualPos)
		}
	}

	return true
}

export default { standard, edges }
