import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { ThreeInit } from '../init'

class cameraController {
	three: ThreeInit
	camera
	//camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
	controls
	target
	scroll: number
	orbit = false
	ortho = false
	autoTrack = true

	constructor(three) {
		this.three = three
		this.target = new THREE.Vector3(0, 0, 0)

		this.camera = new THREE.PerspectiveCamera(90, 1, 0.01, 1000)

		/*
		this.camera = new THREE.OrthographicCamera(90, 1, 0.01, 1000)
		const width = this.renderer.domElement.width / 500
		const height = this.renderer.domElement.height / 500
		this.camera = new THREE.OrthographicCamera(
			width / -2,
			width / 2,
			height / 2,
			height / -2,
			1,
			1000
		)
		*/

		this.camera.up.set(0, 0, 1)
		this.updateProjection()

		this.scroll = 5
		this.camera.position.z = this.scroll
	}

	switchAutoTrack() {
		this.autoTrack = !this.autoTrack
	}

	switchPerspective() {
		this.ortho = !this.ortho
		if (this.ortho) {
			const width = this.three.renderer.domElement.width / 200
			const height = this.three.renderer.domElement.height / 200
			this.camera = new THREE.OrthographicCamera(
				width / -2,
				width / 2,
				height / 2,
				height / -2,
				0.0001,
				1000
			)
			if (this.controls?.enabled) {
				this.controls = new OrbitControls(
					this.camera,
					this.three.renderer.domElement
				)
				this.controls.update()
				// this.camera = new THREE.PerspectiveCamera(90, 1, 0.01, 1000)
			}
		} else {
			this.camera = new THREE.PerspectiveCamera(90, 1, 0.01, 1000)
			this.camera.position.setZ(2)
			this.camera.lookAt(this.camera.position.clone().setZ(0))
			this.camera.up.set(0, 0, 1)
			this.camera.rotation.z = 0

			if (this.controls?.enabled) {
				this.controls = new OrbitControls(
					this.camera,
					this.three.renderer.domElement
				)
				this.controls.update()
				// this.camera = new THREE.PerspectiveCamera(90, 1, 0.01, 1000)
			}
		}
		this.updateProjection()
	}

	switchOrbit() {
		this.orbit = !this.orbit
		if (this.orbit) {
			this.controls = new OrbitControls(
				this.camera,
				this.three.renderer.domElement
			)
			this.controls.enabled = true
			this.controls.update()
			// this.camera = new THREE.PerspectiveCamera(90, 1, 0.01, 1000)
		} else {
			this.controls.enabled = false
			this.camera.position.setZ(2)
			this.camera.lookAt(this.camera.position.clone().setZ(0))
			this.camera.up.set(0, 0, 1)
			this.camera.rotation.z = 0
		}

		this.updateProjection()
	}

	updateProjection() {
		if (this.ortho) return
		var size = new THREE.Vector2()
		this.three.renderer.getSize(size)

		this.camera.aspect =
			this.three.renderer.domElement.width /
			this.three.renderer.domElement.height
		this.camera.updateProjectionMatrix()
	}

	newTarget(target: THREE.Vector3) {
		console.log('CAMERA NEW TARGET', target)
		this.target = target
		this.scroll = 2
	}

	onScroll(deltaY, deltaX) {
		if (deltaY) {
			this.scroll += (this.camera.position.z * deltaY) / 1000

			if (this.scroll < 1) this.scroll = 1
			if (this.scroll > 10) this.scroll = 10
		}

		if (deltaX) {
			this.camera.fov += (this.camera.fov * deltaX) / 1000
			if (this.camera.fov > 175) this.camera.fov = 175
			this.camera.updateProjectionMatrix()
		}
		//this.camera.fov += this.camera.position.z * deltaX / 1000;
	}

	drag(x, y) {
		this.target.x -= x / (600 / this.scroll)
		this.target.y += y / (600 / this.scroll)
	}

	animate() {
		if (this.orbit) {
			this.controls?.update()
		} else {
			if (this.autoTrack) {
				this.camera.position.lerp(
					new THREE.Vector3(
						this.target.x,
						this.target.y,
						this.scroll
					),
					0.1
				)
			}
		}

		/*
      this.controls.target.lerp(this.target, 0.1);
      this.controls.update();
			*/
		/*
		else {
      this.camera.position.lerp(
				new THREE.Vector3(
					this.camera.position.x,
					this.camera.position.y,
					this.scroll,
				)
				, 0.1);
		}
		*/
	}
}

export default cameraController
