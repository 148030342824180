import Avial, { AvialType } from '@ledr/ts-client'
import { renderToStaticMarkup } from 'react-dom/server'
import Label from '../../components/three/components/Label'
import { FunctionComponent, useState, useEffect, useRef } from 'react'
import {
	MapContainer,
	TileLayer,
	Marker,
	ZoomControl,
	useMap,
	Polyline,
	Tooltip,
} from 'react-leaflet'

import * as L from 'leaflet'

import './Leaflet.scss'
import Toolbox from '../../components/hud/Toolbox'
import { Panel, PanelContent } from '../../components/hud/Panel'

import Vector from './Vector'
import NewPoint from './NewPoint'

interface LeafletProps {
	moveEnd: (volume: any, target: any, zoom: any) => void
	position: any
	points: Array<any>
	newPoint: (
		entity: AvialType.Values.V_Entity,
		name: string,
		coordinate: any,
		category: string,
		klass: string
	) => void
	deletePoint: (
		entity: AvialType.Values.V_Entity,
		name: string,
		coordinate: any,
		category: string,
		klass: string
	) => void
	onSelect: (entity) => void
}

const dimentions = [
	{ name: 'X', color: '#ff6464', min: -180, max: 180, step: 0.0000001 },
	{ name: 'Y', color: '#64ff64', min: -90, max: 90, step: 0.0000001 },
	{ name: 'Z', color: '#6464ff', min: 0, max: 10, step: 1 },
]

let layers = [
	{
		name: 'dark no label',
		url: 'https://abcd.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png',
	},
	{
		name: 'dark',
		url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
	},
	{
		name: 'light',
		url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
	},
	{
		name: 'ArcGIS',
		url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
	},
	{
		name: 'topo',
		url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
	},
]

const Leaflet: FunctionComponent<LeafletProps> = (props) => {
	const [map, setMap] = useState(null)
	const [newPoint, setNewPoint] = useState(true)
	const [layer, setLayer] = useState(layers[0])
	const [target, setTarget] = useState([0, 0, 0])
	const [volume, setVolume] = useState([])
	const [resizeObserver, setResizeObserver] = useState(null)

	const ref = useRef<HTMLDivElement>(null)

	function moveEnd() {
		const { _southWest: v1, _northEast: v2 } = map.getBounds()
		let volume = [
			Number(v1.lng.toFixed(7)),
			Number(v1.lat.toFixed(7)),
			0,
			Number(v2.lng.toFixed(7)),
			Number(v2.lat.toFixed(7)),
			map.getZoom(),
		]
		let target = [
			map?.getCenter().lng,
			map?.getCenter().lat,
			map?.getZoom(),
		]

		setVolume(volume)
		setTarget(target)
		props.moveEnd(volume, target, map.getZoom())
	}

	useEffect(() => {
		if (!map) return

		console.log('MAP MAP MAP MAP MAP ')
		//map.setView(center, zoom);
		console.log('MOVEEND CHANGED')

		map.on('click', (e) => {
			console.log('CLICK', e.latlng)
		})

		// map.invalidateSize trigger map.on("moveEnd") so using "dragend" and "zoomend"
		map.off('dragend') // unless, trigger twice
		map.on('dragend', () => {
			console.log('dragend')
			moveEnd()
		})
		map.off('zoomend') // unless, trigger twice
		map.on('zoomend', () => {
			console.log('zoomend')
			moveEnd()
		})

		moveEnd()

		if (!resizeObserver)
			setResizeObserver(
				new ResizeObserver((entry) => {
					try {
						map.invalidateSize()
					} catch (e) {
						console.log('MAP hard destruct resize event', e)
					}
				}).observe(map._container)
			)
	}, [map, resizeObserver])

	useEffect(() => {
		return () => {
			console.log(resizeObserver)
			resizeObserver?.disconnect()
		}
	}, [])

	console.log('BOUNDS', map?.getBounds())
	console.log()

	var corner1 = L.latLng(-90, -180)
	var corner2 = L.latLng(90, 180)

	return (
		<div>
			<MapContainer
				className={'leafWrapper'}
				//@ts-ignore
				center={[props.position.latitude, props.position.longitude]}
				zoom={props.position.zoom}
				//maxBounds={ L.latLngBounds(corner1, corner2) }
				//maxBoundsViscosity={1.0}
				scrollWheelZoom={true}
				zoomControl={false}
				ref={setMap}
				style={{
					width: '100%',
					position: 'absolute',
					height: '100%',
				}}
			>
				<TileLayer url={layer.url} />
				{props.points?.map((e, i) => {
					console.log('MARKER', e)
					return (
						<Marker
							key={i}
							position={[e.coordinates[1], e.coordinates[0]]}
							eventHandlers={{
								click: () => {
									props.onSelect(e.data.entity)
								},
							}}
							//@ts-ignore
							icon={L.divIcon({
								html: renderToStaticMarkup(
									<div className={'jsxMarker'}>
										<Label data={e.data} />
									</div>
								),
								iconSize: [0, 0],
							})}
						>
							{/*
							<Tooltip direction="bottom" >
								{e.data.name}
							<button onClick={()=>{
								//@ts-ignore
								props.deletePoint(new Avial.Values.V_Entity(e.data.entity))
								}}>
								X
							</button>
							</Tooltip>
								*/}
						</Marker>
					)
				})}
			</MapContainer>

			{/* HUD */}
			{newPoint && (
				<>
					<div
						style={{
							position: 'absolute',
							zIndex: 1500,
							width: '100%',
							height: '50%',
							borderBottom: '1px dashed rgba(128,128,128,1)',
							pointerEvents: 'none',
						}}
					></div>
					<div
						style={{
							position: 'absolute',
							zIndex: 1500,
							width: '50%',
							height: '100%',
							borderRight: '1px dashed rgba(128,128,128,1)',
							pointerEvents: 'none',
						}}
					></div>
				</>
			)}

			<Panel position={'topRight'}>
				{newPoint && (
					<PanelContent title={`New Point`}>
						<NewPoint
							dimentions={dimentions}
							center={target}
							onCancel={() => {
								//		setNewPoint(false)
							}}
							onCreate={(
								entity,
								name,
								coordinate,
								category,
								klass
							) => {
								props.newPoint(
									entity,
									name,
									coordinate,
									category,
									klass
								)
								//		setNewPoint(false);
							}}
						/>
					</PanelContent>
				)}
				<PanelContent title={`Points (${props.points?.length})`}>
					<div className={'points'}>
						<div className={'point'}>
							<div> Entity </div>
							<div> Name </div>
							<div> Category </div>
							<div> Class </div>
						</div>

						{props.points?.map((e) => (
							<div
								className={'point'}
								onClick={() => {
									console.log('========', e)
									/*
								map.setView([
								Number(e.coordinates[0]), Number(e.coordinates[1]),
							], Number(e.coordinates[2]))
							*/
									/*
								setPosition({
									latitude: Number(e.coordinates[0]),
									longitude: Number(e.coordinates[1]),
									zoom: Number(e.coordinates[2]),
								})
							*/
								}}
							>
								<div>{e.data.entity}</div>
								<div>{e.data.name}</div>
								<div>{e.data.category.split('_')[0]}</div>
								<div>{e.data.class.split('_')[0]}</div>
								<button
									onClick={() => {
										console.log(e)
										//@ts-ignore
										props.deletePoint(
											new Avial.Values.V_Entity(
												e.data.entity
											),
											e.data.name,
											e.coordinates
										)
									}}
								>
									X
								</button>
							</div>
						))}
					</div>
				</PanelContent>
			</Panel>

			<Panel position={'bottomLeft'}>
				<PanelContent title={`Category`}>
					{props.points
						?.reduce((acc, cur) => {
							return acc.find((e) => e === cur.data.category)
								? acc
								: [...acc, cur.data.category]
						}, [])
						.map((e) => (
							<div style={{ width: '100%' }}>
								<input type="checkbox" />
								{e.split('_')[0]}
							</div>
						))}
				</PanelContent>

				<PanelContent title={`Class`}>
					{props.points
						?.reduce((acc, cur) => {
							return acc.find((e) => e === cur.data.class)
								? acc
								: [...acc, cur.data.class]
						}, [])
						.map((e) => (
							<div style={{ width: '100%' }}>
								<input type="checkbox" />
								{e.split('_')[0]}
							</div>
						))}
				</PanelContent>

				<PanelContent title={'Provider'}>
					{layers.map((e) => (
						<button
							style={
								e.name === layer.name
									? { backgroundColor: 'chocolate' }
									: {}
							}
							onClick={() => {
								setLayer(e)
							}}
						>
							{e.name}
						</button>
					))}
				</PanelContent>

				<PanelContent title={'volume'}>
					<Vector
						name={'start'}
						dimentions={dimentions}
						vector={volume}
					/>
					<Vector
						name={'end'}
						dimentions={dimentions}
						vector={volume.slice(3)}
					/>
				</PanelContent>
			</Panel>
		</div>
	)
}

export default Leaflet
