
import Avial, {AvialType} from "@ledr/ts-client";
import { FunctionComponent, useState, useEffect, useContext } from "react";
import { useCallback } from "react";
import { Handle, Position } from "react-flow-renderer";
import "./adapterNode.scss";
import windows from "../index";
import { ValueInput,  EntityInput, SearchSelect } from "@ledr/instruments";
import { Icon, Print } from "@ledr/instruments";


import ApiContext from "../../../context/api";

interface AdapterNodeProps {
	data: any;
}

const AdapterNode: FunctionComponent<AdapterNodeProps> = (props) => {

	let args = props.data.interface.args.value.map(e => e.value.ASPECT)
	let input = props.data.interface.in;
	let output = props.data.interface.out;

	return (
		//@ts-ignor
		<div className={`flowNode`}>

			<div className="nodeHeader">
				<div>
					{
						//@ts-ignore
						props.data.name
					}
				</div>
				<div>
					{
						//@ts-ignore
						props.data.method
					}
				</div>
				<div>

					{
						//@ts-ignore
						props.data.entity
					}

				</div>
			</div>


			<div className="nodeContent">
				{
					//@ts-ignore
					props.data.description
				}
			</div>

			{/*
				<Print
					value={{
						base : props.data.interface.base.value,
						args: props.data.interface.args,
						in: props.data.interface.in,
						out: props.data.interface.out
					}}
					maxDeepness={10} />
				<hr /> */}

			<div className={"customHandleWrapper"}>
				<div className={"type"}>
					HGTP
				</div>

				{args.map((o,i) =>
				<div className={"customHandle left nodrag nowheel "}>
					<Handle
						className="handle"
						type="target"
						position={Position.Left}
						id={`ARGS_${o}`}
					/>


					<div style={{ display:"flex" }}>
					<div
						style={{ width:"150px"}}
					>
						{o}
					</div>

					<div
						style={{ width:"200px"}}
					>

					{o === "entity" || o === "ENTITY" &&
						<EntityInput
							value={ props.data?.hgtp?.entity ?? new Avial.Values.V_Entity("<0>")}
							onChange={(c)=> {
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										entity: c
									}
								})
							}}
						/>
					}

					{o === "aspect" || o === "ASPECT" &&

					<SearchSelect
						value={props.data?.hgtp?.aspect}
						list={Avial.Taxonomy.Aspect}
						onChange={(c)=>{
							console.log("ON CHANGE ", c)
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										aspect: c
									}
								})
						}}
						placeholder={"Aspect"}
					/>
					}


					{o === "attribute" || o === "ATTRIBUTE" &&
					<SearchSelect
						value={props.data?.hgtp?.attribute}
						list={Avial.Taxonomy.Attribute}
						onChange={(c)=>{
							console.log("ON CHANGE ", c)
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										attribute: c
									}
								})
						}}
						placeholder={"Attribute"}
					/>
					}

					{o === "name" || o === "NAME" &&

					<input
						type="text"
						value={props.data?.hgtp?.name}
						onChange={(c)=>{
							console.log("ON CHANGE ", c)
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										name: c.target.value
									}
								})
						}}
						placeholder={"name"}
					/>
					}

					{o === "key" || o === "KEY" &&

					<input
						type="text"
						value={props.data?.hgtp?.key}
						onChange={(c)=>{
							console.log("ON CHANGE ", c)
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										key: c.target.value
									}
								})
						}}
						placeholder={"key"}
					/>
					}



					{o === "index" || o === "INDEX" &&

					<input
						type="text"
						value={props.data?.hgtp?.index}
						onChange={(c)=>{
							console.log("ON CHANGE ", c)
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										index: c.target.value
									}
								})
						}}
						placeholder={"index"}
					/>
					}
					{o === "instance" || o === "INSTANCE" &&

					<input
						type="text"
						value={props.data?.hgtp?.instance}
						onChange={(c)=>{
							console.log("ON CHANGE ", c)
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
								{
									...props.data,
									hgtp:{
										...(props.data?.hgtp ?? {}),
										instance: c.target.value
									}
								})
						}}
						placeholder={"instance"}
					/>
					}




				</div>

					</div>
				</div>

				)}

			</div>


			<div className={"customHandleWrapper nodrag nowheel"}>
				<div className={"type"}>
					INPUT [{input.type}]
				</div>


				{input.type !== "AGGREGATE" &&
				<div className={"customHandle left"}>
					<Handle
						className="handle"
						type="target"
						position={Position.Left}
						id={`VALUE`}
					/>
					<div style={{display: "flex"}}>
						<ValueInput
							value={ props.data.value ?? props.data.interface.in }
							onChange={(c)=> {
								//@ts-ignore
								props.data.onNodeStateChange(props.id,
									{
										...props.data,
										value:  c
							}
							)
							}}
						/>
						<div>
						</div>
					</div>
				</div>
				}

				{input.type === "AGGREGATE" &&
						input?.map?.((v,k,i) =>
						<div className={"customHandle left"}>
							<Handle
								className="handle"
								type="target"
								position={Position.Left}
								id={`VALUE_${k}`}
							/>
							<div style={{
								display: "flex",
									flexDirection: "column"
								}}>
								<div>
									{k.toUpperCase()}
								</div>
								<ValueInput
									value={ props.data?.value?.value?.[k] ?? v}
									onChange={(c)=> {
										console.log("WILL onNodeStateChange")
										//@ts-ignore
										props.data.onNodeStateChange(props.id,
										{
											...props.data,
											value: new Avial.Values.V_Aggregate(
											{
												...(props.data?.value?.value ?? {}),
													[k]: c 
												} 
										)
									})
									}}
								/>
								<div>
								</div>
							</div>
						</div>
						)}
			</div>


			<div className={"customHandleWrapper"}>
				<div className={"type"}>
					OUTPUT[{output.type}]
				</div>

				{output.type !== "AGGREGATE" &&
				<div className={"customHandle right"}>
					<Handle
						className="handle"
						type="source"
						position={Position.Right}
						id={`VALUE`}
					>
						<div style={{
							position:"relative",
								width: "100px",
								marginLeft: "-100px",
								textAlign:"right",
								//backgroundColor:"green"
							}}>
						</div>
					</Handle>
				</div>
				}

				{output.type === "AGGREGATE" &&
						output?.map?.((v,k,i) =>
						<div className={"customHandle right"}>
							{v.type}
							{k.toUpperCase()}
							<Handle
								className="handle"
								type="source"
								position={Position.Right}
								id={`VALUE_AGGREGATE_${k}`}
							>
								<div style={{
									position:"relative",
										width: "100px",
										marginLeft: "-100px",
										textAlign:"right",
										//backgroundColor:"green"
									}}>
								</div>
							</Handle>
						</div>
						)}



			</div>




			{/*
				<hr />
				<button>Console</button>
				<button>Logs</button>
				<button>out SELECTED_ADAPTER (=>genLoc in Terminal)</button>
				<button>out gen Value etc....</button>
				<Print value={props} />
				*/}
		</div>
	);
};

export default AdapterNode;


